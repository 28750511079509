import React, { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import { setAlert } from 'reducers/globalReducer';
import Layout from 'components/Layout';
import Loading from 'components/Loading';

/* eslint-disable react/display-name  */
/* eslint-disable react/prop-types  */
const withRouter = (WrappedComponent) => (props) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.global.auth);
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const showAlert = useCallback(
    ({ type, title }) => dispatch(setAlert({ type, title })),
    [dispatch]
  );

  useEffect(() => {
    if (props.guestOnly && auth.token) {
      navigate('/');
    } else if (!props.allowGuest && !auth.token) {
      navigate('/login');
    } else {
      setIsLoading(false);
    }
  }, [auth, navigate, props.allowGuest, props.guestOnly]);

  return (
    <>
      <Helmet>
        <title>{props.title}</title>
      </Helmet>
      {isLoading ? (
        <Loading />
      ) : (
        <Layout
          auth={auth}
          navigate={navigate}
          t={t}
          i18n={i18n}
          center={props.center}
          fullHeight={props.fullHeight}
        >
          <WrappedComponent
            {...props}
            t={t}
            i18n={i18n}
            auth={auth}
            navigate={navigate}
            location={location}
            params={params}
            showAlert={showAlert}
          />
        </Layout>
      )}
    </>
  );
};

export default withRouter;
