import React, { memo, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { clearAuth } from 'reducers/globalReducer';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import LogoSvg from 'images/LogoSvg';
import { colors } from 'styles/theme';
import { Link } from 'react-router-dom';
import {
  MenuItem,
  FormControl,
  Select,
  Button,
  Avatar,
  Box,
  Drawer,
  Divider,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

const Wrapper = styled.div`
  height: 100%;
  padding-top: 80px;
  > footer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    font-size: 14px;
    a {
      color: #000;
    }
  }
`;

const Header = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  gap: 16px;
  top: 0;
  left: 0;
  width: 100%;
  padding: 25px 30px;
  background: ${colors.WHITE};
  box-shadow: 0px 4px 4px rgba(221, 221, 221, 0.25);
  z-index: 500;
`;

const MenuItems = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  > div {
    display: flex;
    gap: 16px;
  }
  a {
    text-decoration: none;
    white-space: nowrap;
  }
`;

const MobileMenuItems = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px 24px 40px 24px;
  height: 100%;
  min-width: 240px;
  gap: 16px;
  > div {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  a {
    text-decoration: none;
    white-space: nowrap;
  }
`;

const Content = styled.div`
  position: relative;
  ${({ center }) =>
    center && 'display: flex; align-items: center; justify-content: center;'};
  padding-bottom: 48px;
  padding-top: 10px;
`;

const LogoWrapper = styled.div`
  cursor: pointer;
`;

const OptionLabel = styled.div`
  font-size: 14px;
`;

const Layout = ({ auth, fullHeight, center, children, navigate, t, i18n }) => {
  const isLogin = auth?.token;
  const desktopAvatarRef = useRef();
  const dispatch = useDispatch();
  const [isSideMenuOpen, setIsSideMenuOpen] = useState(false);
  const [openAvatarMenu, setOpenAvatarMenu] = useState(false);
  const [openEmptyHandleDialog, setOpenEmptyHandleDialog] = useState(false);

  const handleLanguageChange = (event) => {
    const { value } = event.target;
    i18n.changeLanguage(value);
    localStorage.setItem('adminLanguage', value);
  };

  const handleLogout = () => {
    dispatch(clearAuth());
    localStorage.removeItem('auth');
    navigate('/login');
  };

  const handleViewMyPageClick = () => {
    if (auth?.handle) {
      window.open(`https://vendgoods.com/${auth.handle}`, '_blank');
    } else {
      setOpenEmptyHandleDialog(true);
    }
  };

  return (
    <Wrapper>
      <Header>
        <MenuIcon
          onClick={() => setIsSideMenuOpen(true)}
          sx={{ display: { md: 'block', lg: 'none' }, cursor: 'pointer' }}
        />
        <LogoWrapper onClick={() => navigate(isLogin ? '/products' : '/login')}>
          <LogoSvg />
        </LogoWrapper>
        {/* Desktop menu */}
        <MenuItems sx={{ display: { xs: 'none', md: 'none', lg: 'flex' } }}>
          <div>
            {auth?.token && (
              <>
                <Button component={Link} to="/products" size="large">
                  {t('page.products')}
                </Button>
                <Button component={Link} to="/setting" size="large">
                  {t('page.settings')}
                </Button>
              </>
            )}
          </div>
          <div>
            <FormControl size="small">
              <Select
                labelId="languageSelector"
                value={i18n.language}
                onChange={handleLanguageChange}
              >
                <MenuItem value="zh-TW">
                  <OptionLabel>{t('zh-TW')}</OptionLabel>
                </MenuItem>
                <MenuItem value="en">
                  <OptionLabel>{t('en')}</OptionLabel>
                </MenuItem>
              </Select>
            </FormControl>
            {auth?.token && (
              <>
                <Button onClick={handleViewMyPageClick} variant="contained">
                  {t('viewMyPage')}
                </Button>
                <Avatar
                  ref={desktopAvatarRef}
                  onClick={() => setOpenAvatarMenu(true)}
                >
                  {auth?.userName?.[0]}
                </Avatar>
                <Popper
                  open={openAvatarMenu}
                  anchorEl={desktopAvatarRef.current}
                  placement="bottom-start"
                  transition
                  disablePortal
                >
                  {({ TransitionProps }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        marginTop: 12,
                      }}
                    >
                      <Paper>
                        <ClickAwayListener
                          onClickAway={() => setOpenAvatarMenu(false)}
                        >
                          <MenuList>
                            <MenuItem onClick={() => navigate('/account')}>
                              {t('page.account')}
                            </MenuItem>
                            <MenuItem onClick={handleLogout}>
                              {t('logout')}
                            </MenuItem>
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </>
            )}
          </div>
        </MenuItems>

        {/* Mobile Menu */}
        <Drawer
          anchor="left"
          open={isSideMenuOpen}
          onClose={() => setIsSideMenuOpen(false)}
          sx={{ display: { md: 'block', lg: 'none' } }}
        >
          <MobileMenuItems>
            <div>
              <CloseIcon
                onClick={() => setIsSideMenuOpen(false)}
                sx={{ cursor: 'pointer' }}
              />
              {auth && (
                <>
                  <Link to="/products">
                    <Button size="large">{t('page.products')}</Button>
                  </Link>
                  <Link to="/setting">
                    <Button size="large">{t('page.settings')}</Button>
                  </Link>
                </>
              )}
            </div>
            <div>
              {auth?.token && (
                <>
                  <Divider />
                  <Button onClick={handleViewMyPageClick} variant="contained">
                    {t('viewMyPage')}
                  </Button>
                  <Box
                    sx={{ display: 'flex', alignItems: 'center', gap: '16px' }}
                  >
                    <Link to="/account">
                      <Avatar>{auth?.userName?.[0]}</Avatar>
                    </Link>
                    {auth?.userName}
                  </Box>
                </>
              )}
              <FormControl size="small">
                <Select
                  labelId="languageSelector"
                  value={i18n.language}
                  onChange={handleLanguageChange}
                >
                  <MenuItem value="zh-TW">{t('zh-TW')}</MenuItem>
                  <MenuItem value="en">{t('en')}</MenuItem>
                </Select>
              </FormControl>
              {auth && <Button onClick={handleLogout}>{t('logout')}</Button>}
            </div>
          </MobileMenuItems>
        </Drawer>
      </Header>
      <Content center={center} fullHeight={fullHeight}>
        {children}
      </Content>
      <Dialog
        open={openEmptyHandleDialog}
        onClose={() => setOpenEmptyHandleDialog(false)}
      >
        <DialogTitle id="alert-dialog-title">
          {t('viewMyPage.error.title')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t('viewMyPage.error.desc')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenEmptyHandleDialog(false)}>
            {t('viewMyPage.error.ok')}
          </Button>
        </DialogActions>
      </Dialog>
      <footer>
        <Link to="/privacy-policy">{t('privacyPolicy')}</Link>
        <div>｜</div>
        <div>{t('contactUs')}</div>
      </footer>
    </Wrapper>
  );
};

Layout.propTypes = {
  auth: PropTypes.object,
  children: PropTypes.node,
  center: PropTypes.bool,
  fullHeight: PropTypes.bool,
  navigate: PropTypes.func,
  t: PropTypes.func,
  i18n: PropTypes.object,
};

export default memo(Layout);
