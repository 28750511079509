import React, { useState, useEffect, useMemo, useReducer } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import useAxios from 'hooks/useAxios';
import { getProduct, createProduct, updateProduct } from 'services';
import withRouter from 'utils/withRouter';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import {
  TextField,
  FormControl,
  Card,
  CardContent,
  Divider,
} from '@mui/material';
import QuillEditor from 'components/QuillEditor';
import { colors } from 'styles/theme';
import UploadArea from 'components/UploadArea';
import Button from 'components/Button';

const InfoWrapper = styled.div`
  flex: 1;
`;

const UploadWrapper = styled.div`
  flex: 1;
  margin: 14px 0 8px 0;
`;

const UploadIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: ${({ thumbnail }) => (thumbnail ? '90px' : '100%')};
  height: ${({ thumbnail }) => (thumbnail ? '90px' : '513px')};
  color: ${colors.BLACK};
  cursor: pointer;
  border: solid 1px #cbcbcb;
  border-radius: 4px;
  font-size: 14px;
  ${({ error }) =>
    error &&
    `
      color: #d32f2f;
      padding: 8px;
      border: 1px solid #d32f2f;
      border-radius: 4px;
    `}

  @media (max-width: 620px) {
    height: ${({ thumbnail }) => (thumbnail ? '90px' : '300px')};
  }
`;

const HelperText = styled.div`
  margin-top: 3px;
  margin-left: 14px;
  font-size: 12px;
  color: ${({ error }) => (error ? '#D32F2F' : '#666666')};
`;

const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 16px;
  width: 100%;
  height: 100%;
`;

const FullImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 513px;
  border: solid 1px #cbcbcb;
  border-radius: 4px;
  img {
    max-width: 100%;
    max-height: 513px;
    object-fit: cover;
  }
  @media (max-width: 620px) {
    display: none;
  }
`;

const Images = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  > div {
    min-width: 90px;
    min-height: 90px;
    width: 90px;
    height: 90px;
    position: relative;
    > div {
      display: none;
    }
    &:hover {
      > div {
        position: absolute;
        display: block;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2);
        transition: all 0.3s ease;
        cursor: pointer;
        svg {
          color: #fff;
          position: absolute;
          right: 4px;
          top: 4px;
        }
      }
    }
  }
  // when screen width is less than 620px, show 2 images per row
  @media (max-width: 620px) {
    > div {
      min-width: 45%;
      min-height: auto;
      width: 45%;
      height: auto;
    }
  }
`;

const Image = styled.img`
  min-width: 90px;
  min-height: 90px;
  width: 90px;
  height: 90px;
  object-fit: cover;
  border: solid
    ${({ selected }) => (selected ? `4px ${colors.PRIMARY}` : '1px #cbcbcb')};
  border-radius: 4px;
  @media (max-width: 620px) {
    width: 100%;
    height: auto;
  }
`;

const ActionButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin: 24px 0 12px 18px;
  @media (max-width: 620px) {
    button {
      width: 100%;
    }
  }
`;

const MAX_IMAGES = 10;

const ProductForm = ({ auth, navigate, params, mode, showAlert, t }) => {
  const productId = params?.id;
  const { status, request } = useAxios();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [selectedImage, setSelectedImage] = useState(0);
  const [previewImages, setPreviewImages] = useState([]);
  const [formData, setFormData] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      name: '',
      status: 'active',
      link: '',
      price: '',
      tag: '',
      description: '',
      couponCode: '',
      images: [],
    }
  );

  const formValidation = useMemo(() => {
    const errors = {};

    if (!formData.name) {
      errors.name = t('product.title.error');
    }
    if (!formData.images.length) {
      errors.image = t('product.image.error');
    }
    if (!formData.link) {
      errors.link = t('product.link.error');
    }

    return {
      isValid: !Object.keys(errors).length,
      ...errors,
    };
  }, [t, formData]);

  const handleImageUploaded = (imageFiles) => {
    setFormData({ images: [...formData.images, ...imageFiles] });
  };

  const handlePreviewImageUpdate = (images) => {
    setPreviewImages((prev) => [...prev, ...images]);
    setSelectedImage(previewImages.length + images.length - 1);
  };

  const handleImageRemove = (index) => {
    setPreviewImages(previewImages.filter((_, i) => i !== index));
    setFormData({ images: formData.images.filter((_, i) => i !== index) });
  };

  const handleSave = () => {
    setIsSubmitted(true);
    if (!formValidation.isValid) return;

    const payload = new FormData();
    Object.keys(formData).forEach((key) => {
      if (key === 'images') {
        formData[key].forEach((image) => {
          payload.append('images[]', image);
        });
      } else if (formData[key] !== null && formData[key] !== undefined) {
        payload.append(key, formData[key]);
      } else {
        payload.delete(key);
      }
    });

    if (mode === 'create') {
      request(createProduct({ userId: auth.userId, payload })).then(() => {
        showAlert({
          type: 'success',
          title: `${t('page.product.create')} ${t('success')}`,
        });
        navigate(`/products`);
      });
    } else {
      request(updateProduct({ userId: auth.userId, productId, payload })).then(
        () => {
          showAlert({
            type: 'success',
            title: `${t('page.product.edit')} ${t('success')}`,
          });
          navigate(`/products`);
        }
      );
    }
  };

  useEffect(() => {
    if (mode === 'edit') {
      request(getProduct({ userId: auth.userId, productId })).then((res) => {
        Object.keys(res).forEach((key) => {
          if (res[key] === null) {
            res[key] = '';
          }
        });
        setFormData({ ...res });
        setPreviewImages(res.images);
      });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Card variant="outlined" sx={{ p: 2 }}>
        <CardContent
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', mobile: 'row-reverse' },
            gap: '32px',
          }}
        >
          <UploadWrapper>
            {!previewImages.length ? (
              <UploadArea
                setPreviewImage={handlePreviewImageUpdate}
                onUploaded={handleImageUploaded}
                multiple
              >
                <UploadIcon error={isSubmitted && !formData.images.length}>
                  {t('product.addImage')}
                </UploadIcon>
                <HelperText error={isSubmitted && !formData.images.length}>
                  {formValidation.image}
                </HelperText>
              </UploadArea>
            ) : (
              <ImageWrapper>
                <FullImage>
                  <img src={previewImages[selectedImage]} />
                </FullImage>
                <Images>
                  {previewImages.map((image, index) => (
                    <div key={index} onClick={() => setSelectedImage(index)}>
                      <Image src={image} selected={selectedImage === index} />
                      <div>
                        <HighlightOffIcon
                          onClick={() => handleImageRemove(index)}
                        />
                      </div>
                    </div>
                  ))}
                  {previewImages.length < MAX_IMAGES && (
                    <UploadArea
                      setPreviewImage={handlePreviewImageUpdate}
                      onUploaded={handleImageUploaded}
                      multiple
                    >
                      <UploadIcon thumbnail>{t('product.addImage')}</UploadIcon>
                    </UploadArea>
                  )}
                </Images>
              </ImageWrapper>
            )}
          </UploadWrapper>
          <InfoWrapper>
            <FormControl margin="normal" fullWidth>
              <TextField
                label={t('product.title')}
                value={formData.name}
                onChange={(e) => setFormData({ name: e.target.value })}
                error={isSubmitted && !!formValidation.name}
                helperText={formValidation.name}
                placeholder={t('product.title.placeholder')}
              />
            </FormControl>
            <FormControl margin="normal" fullWidth>
              <TextField
                label={t('product.link')}
                value={formData.link}
                onChange={(e) => setFormData({ link: e.target.value })}
                error={isSubmitted && !!formValidation.link}
                helperText={formValidation.link}
                placeholder={t('product.link.placeholder')}
              />
            </FormControl>
            <FormControl margin="normal" fullWidth>
              <TextField
                label={t('product.price')}
                value={formData.price}
                onChange={(e) => setFormData({ price: e.target.value })}
                placeholder={t('product.price.placeholder')}
              />
            </FormControl>
            <FormControl margin="normal" fullWidth>
              <TextField
                label={t('product.tag')}
                value={formData.tag}
                onChange={(e) => setFormData({ tag: e.target.value })}
                placeholder={t('product.tag.placeholder')}
              />
            </FormControl>
            <QuillEditor
              label={t('product.desc')}
              value={formData.description}
              onChange={(value) => setFormData({ description: value })}
              placeholder={t('product.desc.placeholder')}
            />
            <FormControl margin="normal" fullWidth>
              <TextField
                label={t('product.couponCode')}
                value={formData.couponCode}
                onChange={(e) => setFormData({ couponCode: e.target.value })}
                placeholder={t('product.couponCode.placeholder')}
              />
            </FormControl>
          </InfoWrapper>
        </CardContent>
        <Divider />
        <ActionButtons>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={handleSave}
            loading={status.loading}
          >
            {t('product.save')}
          </Button>
          <Button
            variant="outlined"
            color="primary"
            size="large"
            onClick={() => navigate(`/products`)}
          >
            {t('product.cancel')}
          </Button>
        </ActionButtons>
      </Card>
    </>
  );
};

ProductForm.propTypes = {
  auth: PropTypes.object,
  navigate: PropTypes.func,
  params: PropTypes.object,
  mode: PropTypes.string,
  showAlert: PropTypes.func,
  t: PropTypes.func,
};

export default withRouter(ProductForm);
