import React, { useState, useReducer, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import withRouter from 'utils/withRouter';
import useAxios from 'hooks/useAxios';
import { isValidPasswordPattern, getUrlParams } from 'utils';
import { resetPassword } from 'services';
import {
  Stack,
  TextField,
  FormControl,
  Button,
  Typography,
  Card,
  CardContent,
  Grid,
  Box,
} from '@mui/material';

const ResetPassword = ({ auth, t, i18n }) => {
  const email = auth.userEmail || getUrlParams('email');
  const resetToken = auth.token || getUrlParams('resetToken');
  const { request } = useAxios();
  const [isReset, setIsReset] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [error, setError] = useState({});
  const [formData, setFormData] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      password: '',
      confirmPassword: '',
    }
  );

  const validation = useCallback(() => {
    setIsSubmitted(true);

    const errors = {};

    if (!isValidPasswordPattern(formData.password)) {
      errors.password = t('resetPassword.newPassword.format');
    }

    if (formData.password !== formData.confirmPassword) {
      errors.confirmPassword = t('resetPassword.newPassword.match');
    }

    setError(errors);

    return Object.keys(errors).length === 0;
  }, [t, formData]);

  const handleReset = () => {
    setIsSubmitted(true);
    if (validation()) {
      request(
        resetPassword({
          resetToken,
          password: formData.password,
          locale: i18n.language,
        })
      ).then(() => {
        setIsReset(true);
      });
    }
  };

  useEffect(() => {
    if (isSubmitted) {
      validation();
    }
  }, [validation, isSubmitted]);

  return (
    <>
      <Grid
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        justifyContent="center"
        alignContent="center"
        sx={{ maxWidth: '1200px' }}
      >
        <Grid item xs={12}>
          <Typography
            variant="h4"
            sx={{
              fontWeight: 'bold',
              mb: 5,
              textAlign: {
                xs: 'center',
                sm: 'center',
                md: 'center',
                lg: 'left',
              },
            }}
          >
            {isReset ? t('resetPassword.success') : t('page.resetPassword')}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card variant="outlined" sx={{ p: 2 }}>
            <CardContent>
              {!isReset ? (
                <>
                  <FormControl margin="normal" fullWidth>
                    <TextField
                      label={t('resetPassword.email')}
                      value={email}
                      type="email"
                      disabled
                    />
                  </FormControl>
                  <FormControl margin="normal" fullWidth>
                    <TextField
                      label={t('resetPassword.newPassword')}
                      value={formData.password}
                      onChange={(e) =>
                        setFormData({ password: e.target.value })
                      }
                      error={error.password}
                      helperText={error.password}
                      placeholder={t('resetPassword.newPassword.placeholder')}
                      type="password"
                    />
                  </FormControl>
                  <FormControl margin="normal" fullWidth>
                    <TextField
                      label={t('resetPassword.newPassword.confirm')}
                      value={formData.confirmPassword}
                      onChange={(e) =>
                        setFormData({ confirmPassword: e.target.value })
                      }
                      error={error.confirmPassword}
                      helperText={error.confirmPassword}
                      placeholder={t('resetPassword.newPassword.confirm')}
                      type="password"
                    />
                  </FormControl>
                  <Stack direction="row" spacing={2} sx={{ mt: 4 }}>
                    <Button
                      onClick={handleReset}
                      size="large"
                      variant="contained"
                      disabled={!email}
                    >
                      {t('resetPassword.confirm')}
                    </Button>
                  </Stack>
                </>
              ) : (
                <Box align="left">
                  <Typography>{t('resetPassword.success.info')}</Typography>
                </Box>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

ResetPassword.propTypes = {
  t: PropTypes.func,
  auth: PropTypes.object,
  i18n: PropTypes.object,
};

export default withRouter(ResetPassword);
