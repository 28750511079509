import React, { useState, useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import withRouter from 'utils/withRouter';
import ImageIcon from '@mui/icons-material/Image';
import useAxios from 'hooks/useAxios';
import { useDispatch } from 'react-redux';
import { setAuth } from 'reducers/globalReducer';
import { getSetting, updateSetting } from 'services';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import QuillEditor from 'components/QuillEditor';
import {
  MenuItem,
  TextField,
  FormControl,
  Switch,
  Card,
  CardContent,
  Divider,
  InputAdornment,
  Avatar,
  IconButton,
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { colors } from 'styles/theme';
import UploadArea from 'components/UploadArea';
import Button from 'components/Button';

const AvatarUploadIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 180px;
  height: 180px;
  border-radius: 50%;
  background-color: ${colors.BACKGROUND};
  border: 1px solid #e0e0ee;
  margin-bottom: 12px;
  cursor: pointer;
  > span {
    margin-top: 8px;
  }
`;

const OptionLabel = styled.div`
  font-size: 14px;
`;

const SwitchWrapper = styled.div`
  > div {
    display: flex;
    align-items: center;
    gap: 8px;
    span {
      font-size: 16px;
    }
  }
  > span {
    font-size: 14px;
    color: #b1b1b1;
  }
`;

const SettingPage = ({ auth, showAlert, t, i18n }) => {
  const dispatch = useDispatch();
  const { status, request } = useAxios();
  const [previewImage, setPreviewImage] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [openAutoSyncDialog, setOpenAutoSyncDialog] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [state, updateState] = useReducer(
    (prev, next) => {
      Object.keys(next).forEach((key) => {
        if (key === 'defaultLocale' && next[key] === null) {
          next[key] = i18n.language;
        }
        if (next[key] === null) {
          next[key] = '';
        }
      });
      return { ...prev, ...next };
    },
    {
      logo: '',
      name: '',
      description: '',
      handle: '',
      defaultLocale: i18n.language,
      autoSync: false,
    }
  );

  const handleCopy = () => {
    navigator.clipboard.writeText(`https://vendgoods.com/${state.handle}`);
    setIsCopied(true);
  };

  const formValidation = () => {
    if (!state.name) {
      return false;
    }
    if (!state.handle) {
      return false;
    }
    if (!/^[a-zA-Z0-9_]+$/.test(state.handle)) {
      return false;
    }
    return true;
  };

  const handleSave = () => {
    setIsSubmitted(true);

    if (!formValidation()) {
      return;
    }

    const payload = new FormData();
    Object.keys(state).forEach((key) => {
      payload.append(key, state[key]);
    });
    request(
      updateSetting({
        userId: auth.userId,
        settingId: state.id,
        payload,
      })
    ).then(() => {
      showAlert({ type: 'success', title: t('success') });
      if (state.handle) {
        const newAuthData = JSON.parse(localStorage.getItem('auth'));
        newAuthData.handle = state.handle;
        localStorage.setItem('auth', JSON.stringify(newAuthData));
        dispatch(setAuth(newAuthData));
      }
    });
  };

  useEffect(() => {
    request(getSetting({ userId: auth.userId })).then((res) => {
      updateState(res);
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {!auth.handle && (
        <Card
          variant="outlined"
          sx={{
            p: 2,
            marginBottom: 2,
            whiteSpace: 'pre-wrap',
            lineHeight: 1.5,
          }}
        >
          {t('setting.info')}
        </Card>
      )}
      <Card variant="outlined" sx={{ p: 2 }}>
        <CardContent>
          <FormControl margin="normal">
            <UploadArea
              setPreviewImage={setPreviewImage}
              onUploaded={(imageFiles) => updateState({ logo: imageFiles[0] })}
            >
              {previewImage || state.logo ? (
                <Avatar
                  src={previewImage || state.logo}
                  sx={{ width: 180, height: 180 }}
                />
              ) : (
                <AvatarUploadIcon>
                  <ImageIcon sx={{ fontSize: 30 }} />
                  <span>{t('setting.addAvatar')}</span>
                </AvatarUploadIcon>
              )}
            </UploadArea>
          </FormControl>
          <FormControl margin="normal" fullWidth>
            <TextField
              label={t('setting.title')}
              value={state.name}
              onChange={(e) => updateState({ name: e.target.value })}
              error={isSubmitted && !state.name}
              helperText={
                isSubmitted && !state.name && t('setting.title.error')
              }
              placeholder={t('setting.title.placeholder')}
            />
          </FormControl>
          <QuillEditor
            label={t('setting.desc')}
            value={state.description}
            onChange={(value) => updateState({ description: value })}
            placeholder={t('setting.desc.placeholder')}
          />
          <FormControl margin="normal" fullWidth>
            <TextField
              label={t('setting.language')}
              value={state.defaultLocale}
              onChange={(e) => updateState({ defaultLocale: e.target.value })}
              error={isSubmitted && !state.defaultLocale}
              helperText={
                isSubmitted &&
                !state.defaultLocale &&
                t('setting.language.error')
              }
              placeholder={t('setting.language.placeholder')}
              select
            >
              <MenuItem value="zh-TW">
                <OptionLabel>{t('zh-TW')}</OptionLabel>
              </MenuItem>
              <MenuItem value="en">
                <OptionLabel>{t('en')}</OptionLabel>
              </MenuItem>
            </TextField>
          </FormControl>

          <FormControl margin="normal" fullWidth>
            <TextField
              label={t('setting.handle')}
              value={state.handle}
              onChange={(e) => updateState({ handle: e.target.value })}
              error={isSubmitted && !state.handle}
              helperText={
                isSubmitted && !state.handle && t('setting.handle.error')
              }
              InputProps={{
                startAdornment: (
                  <div style={{ color: '#a2a2a2' }}>https://vendgoods.com/</div>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <Tooltip
                      title={
                        isCopied
                          ? t('setting.handle.copied')
                          : t('setting.handle.copy')
                      }
                      placement="top"
                      onClose={() => setIsCopied(false)}
                    >
                      <IconButton onClick={handleCopy} edge="end">
                        <ContentCopyIcon />
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>

          <Divider sx={{ margin: '24px 0' }} />
          <SwitchWrapper>
            <div>
              <span>{t('setting.autoSync')}</span>
              <Switch
                checked={state.autoSync}
                onChange={() => setOpenAutoSyncDialog(true)}
                color="primary"
              />
            </div>
            <span>{t('setting.autoSync.desc')}</span>
          </SwitchWrapper>
          <Divider sx={{ margin: '24px 0' }} />
          <FormControl margin="normal" fullWidth>
            <Button
              variant="contained"
              onClick={handleSave}
              size="large"
              sx={{ width: { xs: '100%', mobile: 'fit-content' } }}
              loading={status.loading}
            >
              {t('product.save')}
            </Button>
          </FormControl>
        </CardContent>
      </Card>
      <Dialog
        open={openAutoSyncDialog}
        onClose={() => setOpenAutoSyncDialog(false)}
      >
        <DialogTitle id="alert-dialog-title">
          {t('setting.autoSync.modal.title')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t('setting.autoSync.modal.desc')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenAutoSyncDialog(false)}>
            {t('setting.autoSync.modal.close')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

SettingPage.propTypes = {
  auth: PropTypes.object,
  navigate: PropTypes.func,
  showAlert: PropTypes.func,
  t: PropTypes.func,
  i18n: PropTypes.object,
};

export default withRouter(SettingPage);
