import PropTypes from 'prop-types';
import withRouter from 'utils/withRouter';
import styled from 'styled-components';

const Wrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

const PrivacyPolicy = ({ t }) => {
  return (
    <>
      <Wrapper>
        <h1>{t('privacyPolicy.content1')}</h1>
        <p>{t('privacyPolicy.content2')}</p>
        <h2>{t('privacyPolicy.content3')}</h2>
        <p>{t('privacyPolicy.content4')}</p>
        <h2>{t('privacyPolicy.content5')}</h2>
        <p>{t('privacyPolicy.content6')}</p>
        <h2>{t('privacyPolicy.content7')}</h2>
        <p>{t('privacyPolicy.content8')}</p>
        <h2>{t('privacyPolicy.content9')}</h2>
        <p>{t('privacyPolicy.content10')}</p>
        <h2>{t('privacyPolicy.content11')}</h2>
        <p>{t('privacyPolicy.content12')}</p>
        <li>{t('privacyPolicy.content13')}</li>
        <li>{t('privacyPolicy.content14')}</li>
        <li>{t('privacyPolicy.content15')}</li>
        <li>{t('privacyPolicy.content16')}</li>
        <li>{t('privacyPolicy.content17')}</li>
        <li>{t('privacyPolicy.content18')}</li>
        <h2>{t('privacyPolicy.content19')}</h2>
        <p>{t('privacyPolicy.content20')}</p>
        <h2>{t('privacyPolicy.content21')}</h2>
        <p>{t('privacyPolicy.content22')}</p>
        <h2>{t('privacyPolicy.content23')}</h2>
        <p>{t('privacyPolicy.content24')}</p>
      </Wrapper>
    </>
  );
};

PrivacyPolicy.propTypes = {
  t: PropTypes.func,
};

export default withRouter(PrivacyPolicy);
