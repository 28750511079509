import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { FormControl } from '@mui/material';

const EditorWrapper = styled.div`
  position: relative;
  width: 100%;
  border-radius: 4px;
  border: 1px solid ${({ error }) => (error ? '#D32F2F' : '#bdbdbd')};
  padding: 10px 0 56px 0;
  z-index: 100;
  .ql-toolbar.ql-snow {
    border: none;
    border-bottom: 1px solid ${({ error }) => (error ? '#D32F2F' : '#bdbdbd')};
  }
  .ql-container.ql-snow {
    border: none;
  }
  .ql-container > .ql-editor.ql-blank::before {
    font-size: 16px;
    color: #a2a2a2;
    font-style: normal;
  }
`;

const EditorLabel = styled.div`
  position: absolute;
  left: 10px;
  top: -8px;
  font-size: 12px;
  color: ${({ error }) => (error ? '#D32F2F' : '#666666')};
  background-color: #fff;
  padding: 0 4px;
`;

const HelperText = styled.div`
  margin-top: 3px;
  margin-left: 14px;
  font-size: 12px;
  color: ${({ error }) => (error ? '#D32F2F' : '#666666')};
`;

const QuillEditor = ({
  value,
  onChange,
  label,
  error,
  helperText,
  placeholder,
}) => {
  return (
    <FormControl margin="normal" fullWidth>
      <EditorWrapper error={error}>
        <EditorLabel error={error}>{label}</EditorLabel>
        <ReactQuill
          theme="snow"
          value={value}
          onChange={(value) => onChange(value.replace('<p><br></p>', ''))}
          placeholder={placeholder}
          style={{
            height: '200px',
          }}
        />
      </EditorWrapper>
      <HelperText error={error}>{helperText}</HelperText>
    </FormControl>
  );
};

QuillEditor.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  placeholder: PropTypes.string,
};

export default memo(QuillEditor);
