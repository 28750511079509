import dayjs from 'dayjs';

export const getUrlParams = (key) => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(key);
};

export const getDisplayDate = (date, showWeekDay) => {
  const weekdayMap = {
    0: '日',
    1: '一',
    2: '二',
    3: '三',
    4: '四',
    5: '五',
    6: '六',
  };
  return `${dayjs(date).format('YYYY/MM/DD')}${
    showWeekDay ? `(星期${weekdayMap[dayjs(date).day()]})` : ''
  }`;
};

export const getDisplayHourAndMinute = (date) => {
  return `${dayjs(date).format('HH:mm')}`;
};

export const isValidEmail = (email) =>
  !!email.match(
    /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
  );

export const isValidPasswordPattern = (password) =>
  !!password.match(
    /^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]{8,}$/
  );
