import React, { useEffect, useRef, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { setAuth, setAlert, setErrorMessage } from 'reducers/globalReducer';
import styled from '@emotion/styled';
import { Routes, Route } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

import Home from 'pages/Home';
import Login from 'pages/Login';
import Register from 'pages/Register';
import Account from 'pages/Account';
import Setting from 'pages/Setting';
import ProductList from 'pages/ProductList';
import ProductForm from 'pages/ProductForm';
import ForgetPassword from 'pages/ForgetPassword';
import ResetPassword from 'pages/ResetPassword';
import PrivacyPolicy from 'pages/PrivacyPolicy';
import { getUrlParams } from 'utils';
import { colors } from 'styles/theme';

const AppWrapper = styled.div`
  height: auto !important;
  min-height: 100%;
  padding: 16px;
  background-color: ${colors.BACKGROUND};
`;

const AlertWrap = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 110px;
  left: 16px;
  width: calc(100% - 32px);
  opacity: 0;

  ${({ show }) =>
    show
      ? `
          transition: opacity 0.5s ease-in-out;
          opacity: 1;
        `
      : 'pointer-events: none;'}
`;

const App = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const alert = useSelector((state) => state.global.alert);
  const errorMessage = useSelector((state) => state.global.errorMessage);
  const timeoutRef = useRef();
  const [isInit, setIsInt] = useState(false);

  const handleClearAlert = useCallback(() => {
    clearTimeout(timeoutRef.current);
    dispatch(setAlert(null));
  }, [dispatch]);

  useEffect(() => {
    if (alert) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(() => dispatch(setAlert(null)), 3000);
    }
    return () => clearTimeout(timeoutRef.current);
  }, [alert, dispatch]);

  useEffect(() => {
    const presetLanguage =
      getUrlParams('language') ||
      localStorage.getItem('adminLanguage') ||
      navigator.language;
    const language = /en/.test(presetLanguage) ? 'en' : 'zh-TW';
    i18n.changeLanguage(language);
    localStorage.setItem('adminLanguage', language);

    const auth = JSON.parse(localStorage.getItem('auth'));
    dispatch(setAuth(auth));

    setIsInt(true);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    isInit && (
      <AppWrapper>
        <Routes>
          <Route path="/" element={<Home />} allowGuest />
          <Route
            path="/login"
            element={
              <Login
                title={t('page.login')}
                allowGuest
                guestOnly
                center
                fullHeight
              />
            }
          />
          <Route
            path="/privacy-policy"
            element={
              <PrivacyPolicy title={t('page.privacyPolicy')} allowGuest />
            }
          />
          <Route
            path="/register"
            element={
              <Register
                title={t('page.register')}
                allowGuest
                guestOnly
                center
                fullHeight
              />
            }
          />
          <Route
            path="/forget-password"
            element={
              <ForgetPassword
                title={t('page.forgetPassword')}
                allowGuest
                guestOnly
                center
                fullHeight
              />
            }
          />
          <Route
            path="/reset-password"
            element={
              <ResetPassword
                title={t('page.resetPassword')}
                allowGuest
                center
                fullHeight
              />
            }
          />
          <Route
            path="/account"
            element={<Account title={t('page.account')} />}
          />
          <Route
            path="/setting"
            element={<Setting title={t('page.settings')} />}
          />
          <Route
            path="/products"
            element={<ProductList title={t('page.products')} />}
          />
          <Route
            path="/products/create"
            element={
              <ProductForm title={t('page.product.create')} mode="create" />
            }
          />
          <Route
            path="/products/:id"
            element={<ProductForm title={t('page.product.edit')} mode="edit" />}
          />
        </Routes>

        <AlertWrap show={!!alert || !!errorMessage}>
          {alert && (
            <Alert onClose={handleClearAlert} severity={alert?.type}>
              <AlertTitle>{alert?.title}</AlertTitle>
              {alert?.description}
            </Alert>
          )}
          {errorMessage && (
            <Alert
              onClose={() => dispatch(setErrorMessage(null))}
              severity="error"
            >
              {errorMessage}
            </Alert>
          )}
        </AlertWrap>
      </AppWrapper>
    )
  );
};

export default App;
