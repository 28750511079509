import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setAuth } from 'reducers/globalReducer';
import useAxios from 'hooks/useAxios';
import { login } from 'services';
import PropTypes from 'prop-types';
import withRouter from 'utils/withRouter';
import { isValidEmail } from 'utils';
import {
  Stack,
  TextField,
  FormControl,
  Typography,
  Card,
  CardContent,
  Grid,
} from '@mui/material';
import Button from 'components/Button';

const Login = ({ navigate, t }) => {
  const dispatch = useDispatch();
  const { request, status } = useAxios();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const validation = () => {
    if (!isValidEmail(email)) {
      setError({ type: 'email', message: t('login.email.error') });
      return false;
    }
    return true;
  };

  const handleLogin = async () => {
    setIsSubmitted(true);
    const isValidUser = validation();
    if (isValidUser) {
      request(login({ email, password })).then(
        ({ userId, token, handle, name: userName = 'N/A' }) => {
          const authData = {
            token,
            handle,
            userId,
            userName,
            userEmail: email,
          };
          dispatch(setAuth(authData));
          localStorage.setItem('auth', JSON.stringify(authData));
          if (!handle) {
            navigate('/setting');
          } else {
            navigate('/products');
          }
        }
      );
    }
  };

  useEffect(() => {
    if (isSubmitted) {
      setError(
        isValidEmail(email)
          ? null
          : { type: 'email', message: t('login.email.error') }
      );
    }
  }, [t, email, password, isSubmitted]);

  return (
    <>
      <Grid
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        justifyContent="center"
        sx={{ maxWidth: '1200px' }}
      >
        <Grid item xs={12} md={6}>
          <Typography
            variant="h4"
            sx={{
              fontWeight: 'bold',
              mb: 5,
              textAlign: {
                xs: 'center',
                sm: 'center',
                md: 'center',
                lg: 'left',
              },
            }}
          >
            {t('login.button')}
          </Typography>
          <Card variant="outlined" sx={{ p: 2 }}>
            <CardContent>
              <FormControl margin="normal" fullWidth>
                <TextField
                  label={t('login.email')}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  error={!!error}
                  helperText={error?.type === 'email' && error?.message}
                  placeholder={t('login.email.placeholder')}
                  type="email"
                />
              </FormControl>
              <FormControl margin="normal" fullWidth>
                <TextField
                  label={t('login.password')}
                  value={password}
                  error={error?.type === 'login'}
                  helperText={error?.type === 'login' && error?.message}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder={t('login.password.placeholder')}
                  type="password"
                  sx={{ mb: 2 }}
                />
                <Button
                  variant="text"
                  onClick={() => navigate('/forget-password')}
                  sx={{ width: 'fit-content' }}
                >
                  {t('login.forgetPassword')}
                </Button>
              </FormControl>
              <Stack direction="row" spacing={2} sx={{ mt: 4 }}>
                <Button
                  onClick={handleLogin}
                  size="large"
                  variant="contained"
                  disabled={!email || !password}
                  loading={status.loading}
                >
                  {t('login.button')}
                </Button>
                <Button
                  variant="text"
                  size="large"
                  onClick={() => navigate('/register')}
                >
                  {t('login.register')}
                </Button>
              </Stack>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

Login.propTypes = {
  t: PropTypes.func,
  navigate: PropTypes.func,
};

export default withRouter(Login);
