// API document: https://www.notion.so/guahsu/VendGoods-de0da0dafe894c7fbf7353803cc3f141

const API_URL = process.env.REACT_APP_API_URL;

/* COMMON */
export const refreshToken = () => ({
  url: `${API_URL}/api/refreshToken`,
  method: 'POST',
});

export const sendResetPasswordEmail = ({ email, locale }) => ({
  url: `${API_URL}/api/resetPassword`,
  method: 'POST',
  data: { email, locale },
});

export const resetPassword = ({ resetToken, password, locale }) => ({
  url: `${API_URL}/api/resetPassword`,
  method: 'PUT',
  data: { password, locale },
  headers: {
    resetToken,
  },
});

// User
export const register = ({
  email,
  password,
  validationCode,
  refId,
  locale,
}) => ({
  url: `${API_URL}/api/register`,
  method: 'POST',
  data: {
    email,
    password,
    validationCode,
    refId,
    locale,
  },
});

export const sendValidationCode = ({ type, email, locale }) => ({
  url: `${API_URL}/api/sendValidationCode`,
  method: 'POST',
  data: { type, email, locale },
});

export const login = (payload) => ({
  url: `${API_URL}/api/login`,
  method: 'POST',
  data: payload,
});

// account
export const getAccountData = ({ userId }) => ({
  url: `${API_URL}/api/${userId}/accounts`,
  method: 'GET',
});

export const updateAccountData = ({ userId, accountId, payload }) => ({
  url: `${API_URL}/api/${userId}/accounts/${accountId}`,
  method: 'PUT',
  data: payload,
});

// setting
export const getSetting = ({ userId }) => ({
  url: `${API_URL}/api/${userId}/settings`,
  method: 'GET',
});

export const updateSetting = ({ userId, settingId, payload }) => ({
  url: `${API_URL}/api/${userId}/settings/${settingId}`,
  method: 'PUT',
  data: payload,
});

export const getAnalytics = ({ userId, startDate, endDate }) => ({
  url: `${API_URL}/api/${userId}/analytics`,
  method: 'GET',
  params: { startDate, endDate },
});

// products
export const getProducts = ({ userId }) => ({
  url: `${API_URL}/api/${userId}/products`,
  method: 'GET',
});

export const getProduct = ({ userId, productId }) => ({
  url: `${API_URL}/api/${userId}/products/${productId}`,
  method: 'GET',
});

export const deleteProduct = ({ userId, productId }) => ({
  url: `${API_URL}/api/${userId}/products/${productId}`,
  method: 'DELETE',
});

export const createProduct = ({ userId, payload }) => ({
  url: `${API_URL}/api/${userId}/products`,
  method: 'POST',
  data: payload,
});

export const updateProduct = ({ userId, productId, payload }) => ({
  url: `${API_URL}/api/${userId}/products/${productId}`,
  method: 'PUT',
  data: payload,
});
