import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import useAxios from 'hooks/useAxios';
import {
  getProducts,
  getAnalytics,
  deleteProduct,
  updateProduct,
} from 'services';
import withRouter from 'utils/withRouter';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PercentIcon from '@mui/icons-material/Percent';
import TouchAppIcon from '@mui/icons-material/TouchApp';
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Select,
  MenuItem,
  Tooltip,
} from '@mui/material';
import { colors } from 'styles/theme';

const DateSelector = styled.div`
  div {
    font-size: 14px;
    padding-left: 2px;
  }
`;

const ActionButtons = styled.div`
  font-size: 14px;
  display: flex;
  align-content: center;
  justify-content: ${({ justifyContent }) => justifyContent || 'flex-end'};
  gap: 16px;
  ${({ marginBottom }) => marginBottom && `margin-bottom: ${marginBottom}px;`}
  > div {
    display: flex;
    align-items: center;
  }
`;

const ProductName = styled.div`
  display: flex;
  align-items: center;
  img {
    width: 60px;
    height: 60px;
    object-fit: cover;
    margin-right: 16px;
  }
`;

const DesktopProducts = styled.div`
  display: block;
  @media (max-width: 768px) {
    display: none;
  }
`;

const MobileProducts = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
`;

const MobileProduct = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  padding: 16px;
`;

const ProductInfo = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;
  img {
    width: 60px;
    height: 60px;
  }
  > div {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 16px;
    > div {
      display: flex;
      align-items: center;
      gap: 16px;
      > span {
        width: 33%;
        display: flex;
        align-items: center;
        gap: 4px;
      }
    }
  }
`;

const columns = (t) => [
  {
    id: 'name',
    label: t('list.product.title'),
    minWidth: 170,
    render: ({ row }) => (
      <ProductName>
        <img src={row.imageUrl} />
        {row.name}
      </ProductName>
    ),
  },
  {
    id: 'viewCount',
    label: t('list.product.views'),
    minWidth: 100,
    render: ({ value }) => value || '-',
  },
  {
    id: 'clickCount',
    label: t('list.product.linkClicks'),
    minWidth: 100,
    render: ({ value }) => value || '-',
  },
  {
    id: 'clickRate',
    label: t('list.product.clickThroughRate'),
    minWidth: 100,
    render: ({ value }) => (value ? `${value}%` : '-'),
  },
  {
    id: 'actions',
    label: '',
    minWidth: 100,
    render: ({ row, openDeleteDialog, handleActiveToggle }) => (
      <ActionButtons>
        <Button
          component={Link}
          to={`/products/${row.id}`}
          variant="contained"
          color="primary"
        >
          {t('list.product.edit')}
        </Button>
        <Button
          onClick={() => handleActiveToggle(row)}
          variant="outlined"
          color="primary"
        >
          {row.status === 'active'
            ? t('list.product.unpublish')
            : t('list.product.publish')}
        </Button>
        <Button
          onClick={() => openDeleteDialog(row.id)}
          variant="outlined"
          color="error"
        >
          {t('list.product.delete')}
        </Button>
      </ActionButtons>
    ),
  },
];

const analyticsMap = (t) => [
  {
    key: 'pageView',
    label: t('list.homePageView'),
  },
  {
    key: 'allProductViewCount',
    label: t('list.productView'),
  },
  {
    key: 'allLinkClickCount',
    label: t('list.linkClicks'),
  },
  {
    key: 'averageClickRate',
    label: t('list.clickThroughRate'),
  },
];

const AnalyticsBlockWrapper = styled.div`
  display: flex;
  gap: 32px;
  margin-bottom: 32px;
  overflow: auto;
  @media (max-width: 768px) {
    gap: 16px;
  }
`;

const AnalyticsBlock = styled.div`
  display: flex;
  flex: 1;
  gap: 12px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${colors.WHITE};
  border: 1px solid rgba(224, 224, 224, 1);
  border-radius: 5px;
  padding: 16px;
  min-width: 250px;
  span {
    font-size: 22px;
    &:first-of-type {
      font-size: 18px;
      font-weight: 700;
    }
  }
  @media (max-width: 768px) {
    padding: 8px;
    min-width: 210px;
    gap: 8px;
    text-align: center;
    span {
      font-size: 22px;
      &:first-of-type {
        font-size: 16px;
        font-weight: 700;
      }
    }
  }
`;

const EmptyBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  padding: 200px 24px;
  font-size: 18px;
  div:first-of-type {
    font-weight: 600;
    font-size: 22px;
  }
`;

const MAX_PRODUCTS = 20;

const rangeOptions = [7, 14, 30];

function getRangeDate(value) {
  const today = new Date();
  const endDate = new Date(today);
  const startDate = new Date(today);
  startDate.setDate(startDate.getDate() - (value - 1));

  return {
    startDate: startDate.toISOString().slice(0, 10),
    endDate: endDate.toISOString().slice(0, 10),
  };
}

const ProductListPage = ({ auth, navigate, showAlert, t }) => {
  const userId = auth.userId;
  const { request } = useAxios();
  const [analyticsRange, setAnalyticsRange] = useState(7);
  const [products, setProducts] = useState([]);
  const [analytics, setAnalytics] = useState({});
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isLimitDialogOpen, setIsLimitDialogOpen] = useState(false);
  const [deleteProductId, setDeleteProductId] = useState(null);

  const openDeleteDialog = (productId) => {
    setDeleteProductId(productId);
    setIsDeleteDialogOpen(true);
  };

  const closeDeleteDialog = () => {
    setDeleteProductId(null);
    setIsDeleteDialogOpen(false);
  };

  const handleDateRangeChange = (option) => {
    const value = option.target.value;
    fetchAnalytics(value);
    setAnalyticsRange(value);
  };

  const handleAddProduct = () => {
    if (products.length < MAX_PRODUCTS) {
      navigate('/products/create');
    } else {
      setIsLimitDialogOpen(true);
    }
  };

  const handleDelete = () => {
    request(
      deleteProduct({ userId: auth.userId, productId: deleteProductId })
    ).then(() => {
      const newProducts = products.filter(
        (product) => product.id !== deleteProductId
      );
      setProducts(newProducts);
      closeDeleteDialog();
      showAlert({
        type: 'success',
        title: `${t('list.product.delete.modal.title')} ${t('success')}`,
      });
    });
  };

  const handleActiveToggle = (row) => {
    const { id, name, status } = row;
    request(
      updateProduct({
        userId: auth.userId,
        productId: id,
        payload: { status: status === 'active' ? 'inactive' : 'active' },
      })
    ).then(() => {
      const newProducts = products.map((product) =>
        product.id === id
          ? { ...product, status: status === 'active' ? 'inactive' : 'active' }
          : product
      );
      setProducts(newProducts);
      showAlert({
        type: 'success',
        title: `${name} - ${
          status === 'active'
            ? t('list.product.unpublish')
            : t('list.product.publish')
        } ${t('success')}`,
      });
    });
  };

  const fetchAnalytics = (analyticsRange = 7) => {
    const { startDate, endDate } = getRangeDate(analyticsRange);
    request(getAnalytics({ userId, startDate, endDate })).then((res) => {
      setAnalytics(res);
    });
  };

  useEffect(() => {
    const userId = auth.userId;
    const fetchData = async () => {
      try {
        const analyticsResponse = await request(getAnalytics({ userId }));
        const productsResponse = await request(getProducts({ userId }));
        const productsData = analyticsResponse.products;
        const updatedProducts = productsResponse.products.map((product) => {
          const productData = productsData[product.id];
          const clickCount = productData ? productData.clickCount : 0;
          const viewCount = productData ? productData.viewCount : 0;
          const clickRate = productData ? productData.clickRate : 0;
          return { ...product, clickCount, viewCount, clickRate };
        });
        setProducts(updatedProducts);
        fetchAnalytics();
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <DateSelector>
        <FormControl size="small" sx={{ marginBottom: 2 }}>
          <Select
            labelId="languageSelector"
            value={analyticsRange}
            onChange={handleDateRangeChange}
            sx={{
              boxShadow: 'none',
              border: 'none',
              '.MuiOutlinedInput-notchedOutline': { border: 'none' },
            }}
          >
            {rangeOptions.map((value) => (
              <MenuItem key={value} value={value}>
                {`${t('list.lastDay.1')} ${value} ${t('list.lastDay.2')}`}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DateSelector>
      <AnalyticsBlockWrapper>
        {analyticsMap(t).map(({ key, label }) => (
          <AnalyticsBlock key={key}>
            <span>{label}</span>
            <span>
              {key === 'averageClickRate'
                ? `${analytics[key] || 0}%`
                : analytics[key]?.toLocaleString()}
            </span>
          </AnalyticsBlock>
        ))}
      </AnalyticsBlockWrapper>
      <ActionButtons justifyContent="space-between" marginBottom={24}>
        <Button onClick={handleAddProduct} variant="contained" color="primary">
          {t('list.addProduct')}
        </Button>
        <div>
          {t('list.productCount')}
          {` ${products.length}/${MAX_PRODUCTS}`}
        </div>
      </ActionButtons>
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        {products.length ? (
          <>
            {/* mobile view */}
            <MobileProducts>
              {products.map((product) => {
                const {
                  id,
                  status,
                  imageUrl,
                  name,
                  viewCount,
                  clickCount,
                  clickRate,
                } = product;
                return (
                  <MobileProduct key={id}>
                    <ProductInfo>
                      <img src={imageUrl} />
                      <div>
                        <span>{name}</span>
                        <div>
                          <span>
                            <Tooltip
                              title={t('list.homePageView')}
                              placement="top"
                            >
                              <VisibilityIcon />
                            </Tooltip>
                            {viewCount || '-'}
                          </span>
                          <span>
                            <Tooltip
                              title={t('list.productView')}
                              placement="top"
                            >
                              <TouchAppIcon />
                            </Tooltip>
                            {clickCount || '-'}
                          </span>
                          <span>
                            <Tooltip
                              title={t('list.linkClicks')}
                              placement="top"
                            >
                              <PercentIcon />
                            </Tooltip>
                            {clickRate || '-'}
                          </span>
                        </div>
                      </div>
                    </ProductInfo>
                    <ActionButtons>
                      <Button
                        component={Link}
                        to={`/products/${id}`}
                        variant="contained"
                        color="primary"
                        size="small"
                        fullWidth
                      >
                        {t('list.product.edit')}
                      </Button>
                      <Button
                        onClick={() => handleActiveToggle(product)}
                        variant="outlined"
                        color="primary"
                        size="small"
                        fullWidth
                      >
                        {status === 'active'
                          ? t('list.product.unpublish')
                          : t('list.product.publish')}
                      </Button>
                      <Button
                        onClick={() => openDeleteDialog(id)}
                        variant="outlined"
                        color="error"
                        size="small"
                      >
                        {t('list.product.delete')}
                      </Button>
                    </ActionButtons>
                  </MobileProduct>
                );
              })}
            </MobileProducts>
            {/* desktop view */}
            <DesktopProducts>
              <TableContainer sx={{ maxHeight: 500 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns(t).map((column) => (
                        <TableCell
                          align={column.align}
                          style={{ minWidth: column.minWidth, fontWeight: 700 }}
                          key={column.id}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {products.map((row) => (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id}
                      >
                        {columns(t).map((column) => {
                          const value = row[column.id];
                          return (
                            <TableCell align={column.align} key={column.id}>
                              {column.render({
                                value,
                                row,
                                ...(column.id === 'actions'
                                  ? { handleActiveToggle, openDeleteDialog }
                                  : {}),
                              })}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </DesktopProducts>
          </>
        ) : (
          <EmptyBlock>
            <div>{t('list.product.empty')}</div>
            <div>{t('list.product.empty.desc')}</div>
          </EmptyBlock>
        )}
        <Dialog open={isDeleteDialogOpen} onClose={closeDeleteDialog}>
          <DialogTitle id="alert-dialog-title">
            {t('list.product.delete.modal.title')}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {t('list.product.delete.modal.desc')}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDelete} color="error">
              {t('list.product.delete.modal.confirm')}
            </Button>
            <Button onClick={closeDeleteDialog} color="inherit">
              {t('list.product.delete.modal.cancel')}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={isLimitDialogOpen}
          onClose={() => setIsLimitDialogOpen(false)}
        >
          <DialogTitle>{t('limit.error.title')}</DialogTitle>
          <DialogContent>
            <DialogContentText>{t('limit.error.desc')}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setIsLimitDialogOpen(false)}>
              {t('limit.error.ok')}
            </Button>
          </DialogActions>
        </Dialog>
      </Paper>
    </>
  );
};

ProductListPage.propTypes = {
  t: PropTypes.func,
  auth: PropTypes.object,
  navigate: PropTypes.func,
  showAlert: PropTypes.func,
};

export default withRouter(ProductListPage);
