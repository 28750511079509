import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { store } from 'store';
import { Provider } from 'react-redux';
import App from 'App';
import './i18n';

import 'normalize.css/normalize.css';
import 'styles/global.scss';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { colors } from 'styles/theme';

const theme = createTheme({
  palette: {
    primary: {
      main: colors.BLACK,
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 480,
      mobile: 620,
      md: 768,
      lg: 1024,
      xl: 1200,
    },
  },
  components: {
    MuiTextField: {
      defaultProps: {
        InputLabelProps: { shrink: true },
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: 'contained', color: 'primary' },
          style: {
            backgroundColor: colors.PRIMARY,
            color: colors.BLACK,
            '&:hover': {
              backgroundColor: colors.PRIMARY,
              color: colors.BLACK,
            },
            boxShadow: 'none',
            ':hover': {
              boxShadow: 'none',
            },
          },
        },
      ],
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </ThemeProvider>
);
