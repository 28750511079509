import { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { setErrorMessage, clearAuth } from 'reducers/globalReducer';
import axios from 'axios';

axios.defaults.baseURL = '/';

const skipErrorMessageList = ['validation code invalid'];

const useAxios = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.global.auth);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const request = useCallback(
    async (config) => {
      setLoading(true);
      setError(null);
      dispatch(setErrorMessage(null));
      try {
        const response = await axios({
          url: config.url,
          method: config.method || 'GET',
          headers: config.headers || {
            Accept: '*/*',
            'Content-Type': 'application/json',
            ...(auth?.token ? { token: auth.token } : {}),
            ...(config.headers || {}),
          },
          data: config.data || null,
          params: config.params || null,
        });
        return Promise.resolve(response.data);
      } catch (error) {
        const errorCode = error?.response?.status;
        const errorMessage =
          error?.response?.data?.error_message ||
          error.message ||
          'Something went wrong';
        if (errorMessage === 'invalid credentials') {
          if (errorCode !== 401) {
            dispatch(setErrorMessage(t('error.login')));
          }
          dispatch(clearAuth());
          localStorage.removeItem('auth');
        } else {
          if (!skipErrorMessageList.includes(errorMessage)) {
            dispatch(setErrorMessage(errorMessage));
            setError(errorMessage);
          }
        }
        return Promise.reject(error);
      } finally {
        setLoading(false);
      }
    },
    [auth, dispatch, t]
  );

  return {
    request,
    status: {
      loading,
      error,
    },
  };
};

export default useAxios;
