import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { FileUploader } from 'react-drag-drop-files';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  cursor: pointer;
`;

export const UPLOAD_STATUS = {
  INITIAL: 'initial',
  UPLOADING: 'uploading',
  DONE: 'done',
};

const fileTypes = ['JPG', 'JPEG', 'PNG', 'GIF'];

const fileCheck = (file) => {
  if (!file) {
    alert('請選擇檔案');
    return false;
  }
  if (file.size / (1024 * 1024) > 4) {
    alert('檔案大小必須小於 4 MB');
    return false;
  }
  if (
    !['image/png', 'image/jpg', 'image/jpeg', 'image/gif'].includes(file.type)
  ) {
    alert('檔案格式必須為jpg/png/gif');
    return false;
  }
  return true;
};

const UploadArea = ({
  children,
  setPreviewImage = () => {},
  onUploaded = () => {},
  multiple = false,
}) => {
  const handleUpload = async (data) => {
    const files = multiple ? Array.from(data) : [data];
    const previewImages = [];
    for (let i = 0; i < files.length; i += 1) {
      if (fileCheck(files[i])) {
        previewImages.push(URL.createObjectURL(files[i]));
      }
    }
    onUploaded(files);
    setPreviewImage(previewImages);
  };

  return (
    <Wrapper>
      <FileUploader
        hoverTitle=" "
        multiple={multiple}
        handleChange={handleUpload}
        types={fileTypes}
      >
        {children}
      </FileUploader>
    </Wrapper>
  );
};

UploadArea.propTypes = {
  children: PropTypes.node,
  onUploaded: PropTypes.func,
  setPreviewImage: PropTypes.func,
  multiple: PropTypes.bool,
};

export default memo(UploadArea);
