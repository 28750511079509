import React, { useState, useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import withRouter from 'utils/withRouter';
import useAxios from 'hooks/useAxios';
import { getAccountData, updateAccountData } from 'services';
import { useDispatch } from 'react-redux';
import { setAuth } from 'reducers/globalReducer';
import {
  TextField,
  FormControl,
  Card,
  CardContent,
  Divider,
} from '@mui/material';
import Button from 'components/Button';

const Account = ({ auth, navigate, showAlert, t }) => {
  const dispatch = useDispatch();
  const { request, status } = useAxios();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [state, updateState] = useReducer(
    (prev, next) => ({ ...prev, ...next }),
    {
      name: '',
      email: '',
    }
  );

  const handleSave = () => {
    setIsSubmitted(true);
    if (!state.name || !state.email) return;
    request(
      updateAccountData({
        userId: auth.userId,
        accountId: state.id,
        payload: { name: state.name },
      })
    ).then(() => {
      const newAuthData = JSON.parse(localStorage.getItem('auth'));
      newAuthData.userName = state.name;
      localStorage.setItem('auth', JSON.stringify(newAuthData));
      dispatch(setAuth(newAuthData));
      showAlert({ type: 'success', title: t('success') });
    });
  };

  useEffect(() => {
    request(getAccountData({ userId: auth.userId })).then((res) => {
      updateState(res);
    });
  }, []); // eslint-disable-line

  return (
    <>
      <Card variant="outlined" sx={{ p: 2 }}>
        <CardContent>
          <FormControl margin="normal" fullWidth>
            <TextField
              label={t('account.name')}
              value={state.name}
              onChange={(e) => updateState({ name: e.target.value })}
              error={isSubmitted && !state.name}
              helperText={isSubmitted && !state.name && t('account.name.error')}
              placeholder={t('account.name.placeholder')}
            />
          </FormControl>
          <FormControl margin="normal" fullWidth>
            <TextField
              label={t('account.email')}
              value={state.email}
              disabled
            />
          </FormControl>
          <FormControl margin="normal" fullWidth>
            <Button
              variant="text"
              onClick={() => navigate('/reset-password')}
              sx={{ width: 'fit-content' }}
            >
              {t('account.changePassword')}
            </Button>
          </FormControl>
          <Divider sx={{ margin: '24px 0' }} />
          <FormControl margin="normal" fullWidth>
            <Button
              variant="contained"
              onClick={handleSave}
              sx={{ width: { xs: '100%', mobile: 'fit-content' } }}
              size="large"
              loading={status.loading}
            >
              {t('account.save')}
            </Button>
          </FormControl>
        </CardContent>
      </Card>
    </>
  );
};

Account.propTypes = {
  auth: PropTypes.object,
  navigate: PropTypes.func,
  showAlert: PropTypes.func,
  t: PropTypes.func,
};

export default withRouter(Account);
