import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Button } from '@mui/material';
import PuffLoader from 'react-spinners/PuffLoader';

const Content = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ loading }) => loading && 'color: transparent;'}
`;

const LoadingIcon = styled.div`
  position: absolute;
`;

const StyledButton = ({ children, loading, ...restProps }) => (
  <>
    <Button {...restProps} disabled={restProps?.disabled || loading}>
      <Content loading={loading}>
        {loading && (
          <LoadingIcon>
            <PuffLoader size={24} color="#f6f6f6" />
          </LoadingIcon>
        )}
        {children}
      </Content>
    </Button>
  </>
);

StyledButton.propTypes = {
  children: PropTypes.node,
  loading: PropTypes.bool,
};

export default StyledButton;
