import React, { useState } from 'react';
import PropTypes from 'prop-types';
import withRouter from 'utils/withRouter';
import useAxios from 'hooks/useAxios';
import { isValidEmail } from 'utils';
import { sendResetPasswordEmail } from 'services';
import {
  Stack,
  TextField,
  FormControl,
  Typography,
  Card,
  CardContent,
  Grid,
  Box,
} from '@mui/material';
import Button from 'components/Button';

const ForgetPassword = ({ t, i18n }) => {
  const { request, status } = useAxios();
  const [email, setEmail] = useState('');
  const [isSentResetPasswordEmail, setIsSentResetPasswordEmail] =
    useState(false);
  const [error, setError] = useState(null);

  const handleSendResetPasswordEmail = () => {
    if (isValidEmail(email)) {
      request(sendResetPasswordEmail({ email, locale: i18n.language })).then(
        () => {
          setIsSentResetPasswordEmail(true);
        }
      );
    } else {
      setError(t('forgetPassword.email.error'));
    }
  };

  return (
    <>
      <Grid
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        justifyContent="center"
        alignContent="center"
        sx={{ maxWidth: '1200px' }}
      >
        <Grid item xs={12} md={6}>
          <Typography
            variant="h4"
            sx={{
              fontWeight: 'bold',
              mb: 5,
              textAlign: {
                xs: 'center',
                sm: 'center',
                md: 'center',
                lg: 'left',
              },
            }}
          >
            {t('page.forgetPassword')}
          </Typography>
          <Card variant="outlined" sx={{ p: 2 }}>
            <CardContent>
              {!isSentResetPasswordEmail ? (
                <>
                  <FormControl margin="normal" fullWidth>
                    <TextField
                      label={t('forgetPassword.email')}
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      error={!!error}
                      helperText={error}
                      placeholder={t('forgetPassword.email.placeholder')}
                      type="email"
                    />
                  </FormControl>
                  <Stack direction="row" spacing={2} sx={{ mt: 4 }}>
                    <Button
                      onClick={handleSendResetPasswordEmail}
                      size="large"
                      variant="contained"
                      disabled={!email}
                      loading={status.loading}
                    >
                      {t('forgetPassword.next')}
                    </Button>
                  </Stack>
                </>
              ) : (
                <Box align="left">
                  <Typography>{t('forgetPassword.info.1')}</Typography>
                  <Typography sx={{ my: 1 }}>{email}</Typography>
                  <Typography>{t('forgetPassword.info.2')}</Typography>
                </Box>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

ForgetPassword.propTypes = {
  t: PropTypes.func,
  i18n: PropTypes.object,
};

export default withRouter(ForgetPassword);
