import React, { useRef, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import withRouter from 'utils/withRouter';
import useAxios from 'hooks/useAxios';
import { isValidEmail, isValidPasswordPattern, getUrlParams } from 'utils';
import { register, sendValidationCode } from 'services';
import {
  Stack,
  TextField,
  FormControl,
  Typography,
  Card,
  CardContent,
  Grid,
} from '@mui/material';
import Button from 'components/Button';

const Register = ({ navigate, showAlert, t, i18n }) => {
  const refId = getUrlParams('refId');
  const timer = useRef(0);
  const { request, status } = useAxios();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [validationCode, setValidationCode] = useState('');
  const [isSentValidationCode, setIsSentValidationCode] = useState(false);
  const [time, setTime] = useState(0);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [error, setError] = useState({});
  const [validationCodeError, setValidationCodeError] = useState(false);

  const validation = useCallback(() => {
    setIsSubmitted(true);

    const errors = {};

    if (!isValidEmail(email)) {
      errors.email = t('register.email.error');
    }

    if (!isValidPasswordPattern(password)) {
      errors.password = t('register.password.error');
    }

    setError(errors);

    return Object.keys(errors).length === 0;
  }, [t, email, password]);

  const handleSendValidationCode = async () => {
    if (!isValidEmail(email)) {
      setError({ email: t('register.email.error') });
      return;
    } else {
      setError({});
    }
    try {
      await request(
        sendValidationCode({ type: 'register', email, locale: i18n.language })
      );
      showAlert({
        type: 'success',
        title: t('register.validation.send.success'),
      });
      setIsSentValidationCode(true);
      setTime(60);
      timer.current = setInterval(() => setTime((prev) => prev - 1), 1000);
    } catch (e) {
      console.log('error');
    }
  };

  const handleRegister = async () => {
    if (validation()) {
      try {
        await request(
          register({
            email,
            password,
            validationCode,
            refId,
            locale: i18n.language,
          })
        );
        window?.gtag('event', 'sign_up', {
          method: 'email',
        });
        showAlert({
          type: 'success',
          title: `${t('page.register')} ${t('success')}`,
        });
        navigate('/login');
      } catch (e) {
        const error = await e;
        const errorMessage = error?.response?.data?.error_message;
        if (errorMessage === 'validation code invalid') {
          setValidationCodeError(true);
        } else {
          setTime(0);
          setIsSentValidationCode(false);
        }
      }
    }
  };

  useEffect(() => {
    if (isSubmitted) {
      validation();
    }
  }, [validation, isSubmitted]);

  useEffect(() => {
    if (time === 0) {
      clearInterval(timer.current);
    }
  }, [time]);

  useEffect(() => () => clearInterval(timer.current), []);

  return (
    <>
      <Grid
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        justifyContent="center"
        sx={{ maxWidth: '1200px' }}
      >
        <Grid item xs={12} md={6}>
          <Typography
            variant="h4"
            sx={{
              fontWeight: 'bold',
              mb: 5,
              textAlign: {
                xs: 'center',
                sm: 'center',
                md: 'center',
                lg: 'left',
              },
            }}
          >
            {t('page.register')}
          </Typography>
          <Card variant="outlined" sx={{ p: 2 }}>
            <CardContent>
              <FormControl margin="normal" fullWidth>
                <TextField
                  label={t('register.email')}
                  placeholder={t('register.email.placeholder')}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  error={!!error.email}
                  helperText={error.email}
                  sx={{ mb: 2 }}
                />
                <Button
                  variant="text"
                  onClick={handleSendValidationCode}
                  sx={{ width: 'fit-content' }}
                  disabled={!!time || !email}
                  loading={status.loading}
                >
                  {time > 0
                    ? `${t('register.validation.resend')} (${time})`
                    : isSentValidationCode
                    ? t('register.validation.resend')
                    : t('register.validation.send')}
                </Button>
              </FormControl>

              {isSentValidationCode && (
                <>
                  <FormControl margin="normal" fullWidth>
                    <TextField
                      label={t('register.validation')}
                      placeholder={t('register.validation.placeholder')}
                      value={validationCode}
                      onChange={(e) => setValidationCode(e.target.value)}
                      error={validationCodeError}
                      helperText={
                        validationCodeError
                          ? t('register.validation.error')
                          : ''
                      }
                    />
                  </FormControl>
                  <FormControl margin="normal" fullWidth>
                    <TextField
                      label={t('register.password')}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      error={!!error.password}
                      helperText={error.password}
                      placeholder={t('register.password.placeholder')}
                      type="password"
                    />
                  </FormControl>
                </>
              )}
              <Stack direction="row" spacing={2} sx={{ mt: 4 }}>
                <Button
                  onClick={handleRegister}
                  size="large"
                  variant="contained"
                  disabled={!email || !validationCode || !password}
                  loading={status.loading}
                >
                  {t('register.button')}
                </Button>
                <Button
                  size="large"
                  variant="text"
                  onClick={() => navigate('/login')}
                >
                  {t('register.login')}
                </Button>
              </Stack>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

Register.propTypes = {
  navigate: PropTypes.func,
  showAlert: PropTypes.func,
  t: PropTypes.func,
  i18n: PropTypes.object,
};

export default withRouter(Register);
